<template>
  <div>
    <div class="top-bar flex flex-col justify-between">
        <div class="account-title">促销</div>
        <div class="nav-bar ">
          <div
            class="bar-list"
            @click="currentTab = 'PromoteMonth'"
            :class="['bar-list', { is_active: currentTab === 'PromoteMonth' }]"
          >
            本月优惠
          </div>
          <div
            class="bar-list"
            @click="currentTab = 'PromoteMaster'"
            :class="['bar-list', { is_active: currentTab === 'PromoteMaster' }]"
          >
            Mastercard@优惠
          </div>
          <div
            class="bar-list"
            @click="currentTab = 'PromotePromote'"
            :class="[
              'bar-list',
              { is_active: currentTab === 'PromotePromote' },
            ]"
          >
            促销
          </div>
      </div>
    </div>
    <div class="bottom">
      <keep-alive>
        <transition mode="out-in">
          <component v-bind:is="currentTab"></component>
        </transition>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import PromoteMonth from '@/components/User/PromoteMonth.vue'
import PromoteMaster from '@/components/User/PromoteMaster.vue'
import PromotePromote from '@/components/User/PromotePromote.vue'

export default {
  data () {
    return {
      currentTab: 'PromoteMonth'
    }
  },
  components: {
    PromoteMonth,
    PromoteMaster,
    PromotePromote
  }
}

</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(150px);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.nav-bar {
  display: flex;
}
.bar-list {
  margin-right: 60px;
}
@media only screen and (max-width: 600px) {
  .bar-list {
    margin-right: 0;
    font-size: 14px;
  }
}
</style>
